import React from 'react';

export const initialTransaction = {
  transaction: null,
};

const TransactionContext = React.createContext(initialTransaction);

export const TransactionProvider = TransactionContext.Provider;

export const TransactionConsumer = TransactionContext.Consumer;

export default TransactionContext;
