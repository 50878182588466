import React from 'react';
import newWithProfile from 'hocs/newWithProfile';
import { Block } from 'components/atoms';

class QrCode extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    return <Block/>;
  }
}

export default newWithProfile(QrCode);
