import React from 'react';
import get from 'lodash/get';
import { Block, Image, Text, Loader } from 'components/atoms';
import CloudBg from 'assets/Cloud_Bg.png';
import dashboardLink from 'assets/dashboardlink.png';
import PumpkinSuperman from 'assets/pumkin-superman.png';
import logo from 'assets/logo.svg';
import { withTheme } from 'hocs';
import Line from 'assets/line-me.png';
import FacebookLogo from 'assets/Facebook.png';
import { Location } from '@reach/router';
import EmailSignIn from 'components/forms/EmailSignIn';
import EmailSignUp from 'components/forms/EmailSignUp';
import { navigate } from 'gatsby';
// import SignUpForm from 'components/forms/SignUpForm';

import firebase from 'appFirebase/config';
import CloudApi from 'api/cloudApi';

// REDUX
import { getProfile } from 'modules/auth/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//HElPERS
import { getLineURL } from 'helpers/constants';
import { decryptQRCode } from 'helpers/functions';
import qs from 'qs';

const provider = new firebase.auth.FacebookAuthProvider();
provider.addScope('user_birthday');
provider.addScope('public_profile');
provider.addScope('email');
provider.addScope('user_gender');

class UserLogin extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      // openEmailForm: false,
      // openSignUpForm: false,
      formState: 'social',
      showLoading: false
    };
  }

  checkGoto = () => {
    const { location } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (query.goto !== undefined) {
      navigate(window.location.pathname + window.location.search);
    } else {
      navigate(`/MyAccount${location.search}`);
    }
  }

  checkGotoFacebook = () => {
    const { location } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (query.goto !== undefined) {
      navigate(window.location.search.slice(6));
    } else {
      navigate(`/MyAccount${location.search}`);
    }
  }

  componentDidMount () {
    const { location } = this.props;
    this.setState({ showLoading: true });
    firebase.auth().getRedirectResult().then((data) => {
      if (data.user && firebase.auth().currentUser) {
        return CloudApi.loginFacebook(data).then(() => this.checkGotoFacebook())
      }
      else {
        this.setState({ showLoading: false });
      }
    })
  }

  checkQueryPath =(query) =>{
    const { location } = this.props;
    if(get(query,'goto')){
      return {goto: location.search}
    }
    else return query.path
  }

  renderForm = () => {
    const { formState } = this.state;
    const { getProfile, location } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    console.log('query', query)

    if (formState === 'signInEmail')
      return (
        <Block width={[200, 420]}>
          <EmailSignIn
            onSubmit={(email, password) =>
              // cannot pass auth.signInWithEmailAndPassword
              // must create new function
              firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(() => {
                  getProfile().then(() => this.checkGoto())
            })
          }
            onExit={() => this.setState({ openSignInForm: false })}
          />
        </Block>
      );
    if (formState === 'signUpEmail')
      return (
        <Block width={[200, 420]} m={'auto'} pt={[50, 20]}>
          <EmailSignUp
            onSubmit={this.onSignUp}
            onExit={() => this.setState({ openSignUpForm: false })}
          />
        </Block>
      );
    return (
      <Block>
        <Block.MiddleCenter
          color={'#03c303'}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          p={['6px', '12px']}
          style={{
            borderRadius: 50,
            color: '#03c303',
            boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.2)',
            cursor: 'pointer'
          }}
          width={[200, 350]}
          height={45}
          href={getLineURL(decryptQRCode(this.checkQueryPath(query)))}
          onClick={() => {
            (window.location = `${getLineURL(decryptQRCode(this.checkQueryPath(query)))}`)
          }
          }
          >
          <Image src={Line} width={[24, 48]} height={[24, 48]} mr={[15, 25]}/>
          <Text
            // className={classes.loginText}
            color={'#03c303'}
            fontWeight={'bold'}
            textAlign={'center'}
            fontSize={['11px', 17]}>
            LOGIN WITH LINE
          </Text>
        </Block.MiddleCenter>
        <Block.MiddleCenter
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          p={['6px', '12px']}
          style={{
            borderRadius: 50,
            boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.2)',
            cursor: 'pointer'
          }}
          width={[200, 350]}
          height={45}
          mt={[18, 25]}
          onClick={() => {
            firebase
              .auth()
              .signInWithRedirect(provider)
            //   .then(data => {
            //     this.setState({ showLoading: true });
            //     return CloudApi.loginFacebook(data).then(() =>
            //       navigate(`/login${location.search}`)
            //     );
            //   })
            // .catch(error => console.log('error', error));
          }}>
          <Image
            src={FacebookLogo}
            width={[24, 48]}
            height={[24, 48]}
            mr={[10, 25]}
          />
          <Text
            // className={classes.loginText}
            color={'#0773f6'}
            fontWeight={'bold'}
            fontSize={['11px', 17]}
            textAlign={'center'}>
            LOGIN WITH FACEBOOK
          </Text>
        </Block.MiddleCenter>
      </Block>
    );
  };

  onSignUp = (email, password) => {
    const { location } = this.props;
    return CloudApi.register({ email, password }).then(() =>
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => this.checkGoto())
    );
  };

  render () {
    return (
      <Location>
        {() => {
          const { formState, showLoading } = this.state;
          return (
            <Block
              position={'relative'}
              display={'flex'}
              flexDirection={'column'}
              height={'100vh'}
              backgroundSize={'cover'}
              justifyContent={'center'}
              alignItems={'center'}
              css={{
                backgroundImage: `url(${CloudBg})`
              }}
              backgroundPosition={'center'}>
              <Image
                src={PumpkinSuperman}
                zIndex={2}
                width={[75, 125, 150]}
                mb={[40, 35, 40]}
                mt={[18, 36, 18]}
                objectFit={'cover'}
              />
              <Image src={logo} width={[120, 220]}/>
              <Block mt={[10, 25]} height={[120, 220]}>
                <Block>
                  {this.renderForm()}
                  <Block.Center mt={[40, 30]} style={{ cursor: 'pointer' }}>
                    <Text
                      color={'#fb970b'}
                      opacity={0.8}
                      fontSize={['10px', 16]}
                      fontWeight={'bold'}
                      mr={[15, 30]}
                      onClick={() =>
                        this.setState({
                          formState:
                            formState === 'signInEmail'
                              ? 'social'
                              : 'signInEmail'
                        })
                      }>
                      {formState === 'signInEmail'
                        ? 'LOGIN WITH SOCAIL'
                        : 'LOGIN WITH EMAIL'}
                    </Text>
                    <Block width={'2px'} background={'#fb970b'} opacity={0.3}/>
                    <Text
                      color={'#fb970b'}
                      opacity={0.8}
                      fontSize={['10px', 16]}
                      ml={[15, 30]}
                      fontWeight={'bold'}
                      onClick={() =>
                        this.setState({
                          formState:
                            formState === 'signUpEmail'
                              ? 'social'
                              : 'signUpEmail'
                        })
                      }>
                      {formState === 'signUpEmail'
                        ? 'LOGIN WITH SOCAIL'
                        : 'SIGN UP'}
                    </Text>{' '}
                  </Block.Center>
                </Block>
              </Block>
              <a href={`${process.env.DASHBOARD_HOST}/login`}>
                <Block
                  position={'absolute'}
                  right={0}
                  width={[25, 50]}
                  height={[200, 400]}
                  top={'30%'}
                  background={`url(${dashboardLink})`}
                  backgroundSize={'cover'}
                />
              </a>
              {showLoading && (
                <Block.MiddleCenter flex={1}>
                  <Loader/>
                </Block.MiddleCenter>
              )}
            </Block>
          );
        }}
      </Location>
    );
  }
}

export default connect(null, dispatch =>
  bindActionCreators(
    {
      getProfile
    },
    dispatch
  )
)(withTheme()(UserLogin));
