import React from 'react';
import { auth, db } from '../firebase/config';
import { Block, Loader } from 'components/atoms';
import { navigate } from 'gatsby';
import qs from 'qs';
import get from 'lodash/get';
// REDUX
import { getProfile } from 'modules/auth/actions';
import { connect } from 'react-redux';
import { selectGetAuthStatus, selectAuthData } from 'modules/auth/selectors';
import { bindActionCreators } from 'redux';

import TransactionModal from 'containers/TransactionModal';
import SurveyModal from 'components/SurveyModal';
import PhoneModal from 'components/organisms/PhoneModal';
import { compose } from 'recompose';

import withTheme from 'hocs/withTheme';
import CloudApi from '../api/cloudApi';
import login from '../pages/login';

const withProfile = WrappedComponent => {
  class CheckAuthProfile extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        transactionData: null,
        latestSurvey: null,
        shouldGetProfile: true,
        phoneNumber: null,
        showPhoneModal: localStorage.getItem('showPhoneModal')
      };
    }

    checkGoto = () => {
      const { location } = this.props;
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (query.path !== undefined) {
        if (query.path.includes('cashier')) {
          navigate(`/MyAccount`);
        } else {
          navigate(query.path.replace(';', '&'));
        }
      } else {
        navigate(`/MyAccount`);
      }
    };

    componentDidMount() {
      // localStorage.setItem('showPhoneModal', 'null');
      const { getProfile, location } = this.props;
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });

      // Line login
      if (query.id_token && query.access_token) {
        return CloudApi.loginWithLine(
          { id_token: query.id_token, path: query.path },
          query.access_token
        ).then(({ token }) => {
          return auth.signInWithCustomToken(token).then(() => this.checkGoto());
        });
      }

      auth.onAuthStateChanged(user => {
        const pathname = get(location, 'pathname', '');
        const re = pathname + location.search;
        const includeGotoPath = '?goto=' + re;

        if (user) {
          if (query.path && query.path.includes('cashier:')) {
            getProfile(query.path);
            return navigate('/MyAccount', { replace: true });
          } else if (pathname.includes('qr-code')) {
            return navigate('/MyAccount', { replace: true });
          } else {
            if (this.state.shouldGetProfile) {
              getProfile();
            }
            return navigate(re, { replace: true });
          }
        } else {
          if (pathname.includes('brand')) {
            return navigate(`/brandlist/${location.search}`, { replace: true });
          }
          if (!query.path) {
            console.log(query);
            console.log(pathname);
            return navigate(`/login/${includeGotoPath}`);
          } else return navigate(`/login/${location.search}`);
        }
      });
    }

    componentWillReceiveProps(nextProps) {
      const { profile: nextProfile } = nextProps;
      const { profile } = this.props;
      if (
        nextProfile !== null &&
        get(nextProfile, 'uid') !== get(profile, 'uid', null)
      ) {
        this.connectRealTimeDb(get(nextProfile, 'uid'));
        const phoneNumber = get(nextProfile, 'phoneNumber', null);
        this.setState({ phoneNumber });
      }
    }

    connectRealTimeDb = uid => {
      const { getProfile } = this.props;
      db.ref(`/transactions/${uid}`).on('value', snapshot => {
        const data = snapshot.val();
        console.log('data', data);
        if (data) {
          getProfile();
          this.setState({ transactionData: data });
        }
      });
      db.ref(`/notifications/${uid}`).on('value', snapshot => {
        if (snapshot.val() && get(snapshot.val(), 'type') === 'survey') {
          this.setState({ latestSurvey: snapshot.val() });
        }
      });
    };

    setTransaction = () => {
      this.setState({ transactionData: null });
    };

    clearNotification = () => {
      const { profile } = this.props;
      this.setState({ latestSurvey: null });
      return db.ref(`/notifications/${profile.uid}`).remove();
    };

    render() {
      const { profile, getProfile } = this.props;
      const {
        transactionData,
        latestSurvey,
        phoneNumber,
        showPhoneModal
      } = this.state;

      if (!profile)
        return (
          <Block.MiddleCenter flex={1}>
            <Loader />
          </Block.MiddleCenter>
        );
      else
        return (
          <Block>
            {transactionData && (
              <TransactionModal
                profile={profile}
                transaction={transactionData}
                setTransactionNull={this.setTransaction}
              />
            )}
            {latestSurvey && (
              <SurveyModal
                open={!!latestSurvey}
                onClose={() => this.clearNotification()}
                uid={profile.uid}
                survey={latestSurvey}
              />
            )}
            {profile &&
              !phoneNumber &&
              showPhoneModal !== 'alreadyShow' &&
              !get(profile, 'phoneNumber') && (
                <PhoneModal
                  open={showPhoneModal !== 'alreadyShow'}
                  onClose={() => {
                    localStorage.setItem('showPhoneModal', 'alreadyShow');
                    this.setState({ showPhoneModal: 'alreadyShow' });
                  }}
                  profile={profile}
                  getProfile={getProfile}
                />
              )}
            <WrappedComponent {...this.props} profile={profile} />
          </Block>
        );
    }
  }

  const mapStateToProps = state => ({
    getProfileStatus: selectGetAuthStatus(state),
    profile: selectAuthData(state)
  });
  return compose(
    connect(mapStateToProps, dispatch =>
      bindActionCreators(
        {
          getProfile
        },
        dispatch
      )
    ),
    withTheme()
  )(CheckAuthProfile);
};

export default withProfile;
