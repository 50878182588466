import React from 'react';
import get from 'lodash/get';
import { css } from '@emotion/core';
import isEmpty from 'lodash/isEmpty';
import { TransactionConsumer } from 'contexts/transactionContext';

import { db } from 'appFirebase/config';

import { Modal } from 'components/molecules';
import { Text, Image, Button } from 'components/atoms';

import { ADD_LINE_LINK, LINE_LOGO } from 'helpers/constants';

const TransactionModal = ({ profile, transaction, setTransactionNull }) => {
  const renderContent = ({
    type,
    campaign,
    brand = {},
    reward = {},
    point,
    newPoint,
    operation
  }) => {
    if (type === 'redemption' && operation === 'void') {
      return (
        <div style={{ textAlign: 'center' }}>
          <Text fontSize={22}>พนักงานได้ทำการยกเลิก</Text>
          <Text fontSize={22}>การแลกของรางวัลจากร้าน</Text>
          <Text fontSize={30} color="primary" style={{ marginTop: 15 }}>
            {brand.name}
          </Text>
          <Image
            src={reward.image}
            style={{
              marginTop: 10,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 80,
              height: 80,
              borderRadius: 10
            }}
          />
          <Text color={'grey'} style={{ marginTop: 5 }}>
            {reward.title}
          </Text>
          <Text fontSize={25} fontWeight={'bold'}>
            คุณเหลือ {newPoint} Pump
          </Text>
        </div>
      );
    }
    if (type === 'redemption') {
      if (campaign && !isEmpty(campaign)) {
        return (
          <div style={{ textAlign: 'center' }}>
            <Text fontSize={22}>คุณได้รับรางวัล จากร้าน</Text>
            <Text fontSize={30} color="primary">
              {brand.name}
            </Text>
            <Image
              src={campaign.image}
              style={{
                marginTop: 10,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 80,
                height: 80,
                borderRadius: 10
              }}
            />
            <Text color={'grey'} style={{ marginTop: 5 }}>
              {campaign.title}
            </Text>
          </div>
        );
      }
      return (
        <div style={{ textAlign: 'center' }}>
          <Text fontSize={22}>คุณได้รับรางวัล จากร้าน</Text>
          <Text fontSize={30} color="primary">
            {brand.name}
          </Text>
          <Image
            src={reward.image}
            style={{
              marginTop: 10,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 80,
              height: 80,
              borderRadius: 10
            }}
          />
          <Text color={'grey'} style={{ marginTop: 5 }}>
            {reward.title}
          </Text>
          <Text fontSize={25} fontWeight={'bold'}>
            คุณเหลือ {newPoint} Pump
          </Text>
        </div>
      );
    }
    if (type === 'transaction' && operation === 'void') {
      return (
        <div style={{ textAlign: 'center' }}>
          <Text fontSize={22}>พนักงานได้ทำการลบแสตมป์ของคุณจากร้าน</Text>
          <Text fontSize={30} color="primary" style={{ marginTop: 15 }}>
            {brand.name}
          </Text>
          <Text fontSize={40} fontWeight={'bold'}>
            {point}
          </Text>
          <Text fontSize={20} color="grey" style={{ marginTop: -12 }}>
            PUMP
          </Text>
        </div>
      );
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <Text fontSize={22}>คุณได้รับแสตมป์ จากร้าน</Text>
        <Text fontSize={30} color="primary" style={{ marginTop: 15 }}>
          {brand.name}
        </Text>
        <Text fontSize={40} fontWeight={'bold'}>
          {point}
        </Text>
        <Text fontSize={20} color="grey" style={{ marginTop: -12 }}>
          PUMP
        </Text>
      </div>
    );
  };
  const addLineSeen = localStorage.getItem('addLineSeen');

  const  isNewCard = transaction? false: true
  return (
    // <TransactionConsumer>
    //   {console.log('transaction', transaction)}
    //   {({ transaction }) => {
    //     if (!transaction) {
    //       return null;
    //     }
    //     const isNewCard = get(transaction, 'isNewCard', false);
    //     const isNewCard = true;
    //     return (
          <Modal
            open
            className={css({
              '&.modal .actions': {
                margin: 0
              }
            })}
            content={renderContent(transaction)}
            onClose={() => {
              if (!addLineSeen) {
                localStorage.setItem('addLineSeen', true);
              }
              db.ref(`/transactions/${get(profile, 'uid')}`).remove();
              setTransactionNull()
            }}
            actions={
               !addLineSeen || isNewCard
                ? [
                    <Button
                      component={'a'}
                      fullWidth
                      variant={'contained'}
                      style={{
                        margin: 0,
                        borderRadius: 0,
                        backgroundColor: '#00c300',
                        padding: 8
                      }}
                      size={'large'}
                      href={ADD_LINE_LINK}
                      target={'_blank'}
                      onClick={() => {
                        if (!addLineSeen) {
                          localStorage.setItem('addLineSeen', true);
                        }
                      }}>
                      <Image src={LINE_LOGO} width={60} height={60} mr={16} />
                      <div>
                        <Text
                          color={'white'}
                          fontSize={24}
                          textAlign={'center'}
                          fontWeight={500}>
                          Add Friend
                        </Text>
                        <Text
                          color={'white'}
                          fontSize={18}
                          letterSpacing={'2px'}
                          fontWeight={200}
                          mt={'-6px'}>
                          เช็คแต้มได้ทุกที่ ทุกเวลา
                        </Text>
                      </div>
                    </Button>
                  ]
                : []
            }
          />
    //     );
    //   }}
    // </TransactionConsumer>
  );
};

export default TransactionModal;
