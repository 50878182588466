import React from 'react';
import get from 'lodash/get';
import qs from 'qs';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import ROUTES from 'routes';

import { Block, Loader } from 'components/atoms';
import { Modal } from 'components/molecules';

import CloudApi from '../api/cloudApi';
import { auth } from '../firebase/config';
import withAuth from './withAuth';

const withAuthProtection = (redirectPath = ROUTES.login.getPath()) =>
  compose(withRouter, withAuth(), WrappedComponent => {
    class AuthProtection extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          errorCode: ''
        };
      }

      componentDidMount() {
        const {
          history,
          location,
          // from context in App.js
          setShowRequestStamp, // context in App.js
          setIsLoggingIn, // context in App.js
          setProfile // context in App.js
        } = this.props;
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        // console.log(query);
        // ONLY WHEN USER CLICK LOGIN LINE
        if (query.id_token && query.access_token) {
          setIsLoggingIn(true); // context in App.js
          if (query.path && query.path.includes('cashier:')) {
            setShowRequestStamp(true);
          }
          // console.log('query.path', query.path)
          return CloudApi.loginWithLine(
            { id_token: query.id_token, path: query.path },
            query.access_token
          )
            .then(({ token, profile }) => {
              // console.log('token', token);
              history.replace('/qr-code');
              return auth.signInWithCustomToken(token).then(() => {
                const currentUser = auth.currentUser;
                // immediately set user because firebase onAuthStateChanged triggered slower than setState
                setProfile(
                  profile,
                  ['me', 'isLoggingIn'],
                  [currentUser, false]
                );
              }); // context in App.js
            })
            .catch(err => {
              console.log('err', err);
              setIsLoggingIn(false); // context in App.js
              this.setState({
                errorCode: get(err, 'error.code')
              });
              history.replace('/qr-code');
            });
        }
      }

      componentWillReceiveProps(nextProps, nextContext) {
        const { me, history, location } = this.props;
        const {
          me: nextMe,
          isFindingAuth: nextFindingAuth,
          isLoggingIn: nextLoggingIn
        } = nextProps;
        if (
          (!nextFindingAuth && !nextMe && !nextLoggingIn) || // case I : user haven't logged in
          (me && !nextMe) // case II : user log out
        ) {
          /*
            SUPER IMPORTANT
            MUST SEND location.search to /login
            OTHERWISE user will not popup in merchant app
            see /controllers/users in pumpkin-backend => /login/line the last then
           */
          return history.replace(`${redirectPath}${location.search}`);
        }
      }

      render() {
        const { isFindingAuth, me } = this.props;
        const shouldRenderLoading = isFindingAuth || !me;
        if (isFindingAuth || !me) {
          return (
            <Block.MiddleCenter flex={1}>
              <Loader />
            </Block.MiddleCenter>
          );
        }
        return (
          <React.Fragment>
            {shouldRenderLoading ? (
              <Block.MiddleCenter flex={1}>
                <Loader />
              </Block.MiddleCenter>
            ) : (
              <WrappedComponent {...this.props} />
            )}
            <Modal content={'โปรดรอพนักงานยืนยันสักครู่...'} />
          </React.Fragment>
        );
      }
    }

    return AuthProtection;
  });

export default withAuthProtection;
